import React from 'react'
import { Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles, Card, Typography, CardActions, Button, CardMedia, CardContent } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: '1 0 100%',
        flexDirection: 'row',
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    details: {
        flex: '1 0 50%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '5%',
        boxSizing: 'border-box;',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingBottom:theme.spacing(2),
            flex: '1 1 100%',
        }


    },
    content:{
       
    },
    imageWrapper: {
        flex: '1 0 50%',
        '&$right': {
            display:'none'
        },
        '&$show': {
            display:'block!important'
        },
        [theme.breakpoints.down('sm')]: {
            display:'flex',
            width: '100%',
            height:'auto',
            '&$right': {
                display:'block!important;'
            },
            '&$left': {
                display:'none!important;'
            },
        }
    },
    cover: {
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            display:'flex',
            width: '100%',
            height:'75vW',
            maxHeight:'100vw'
        }
     
    },
    title: {
        padding: 0,
        marginBottom: '5px',

    },
    button: {
        whiteSpace: 'nowrap',
        color: 'white',
        backgroundColor: 'black',
        borderRadius: 0,
        "&:hover, &:focus": {
            color: 'black',
            backgroundColor: 'white',
        }
    },
    controls: {
        display: 'flex',
        width: '50%',
        gap: '2%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }

    },
    header: {
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        fontWeight: 600,
        fontSize: '2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        }
    },
    greyBg:{
        backgroundColor: theme.palette.primary.main
    },
    subtitle:{
        fontSize:'1.3rem',
        [theme.breakpoints.down('sm')]:{
            fontSize:'1rem',
        }
    },
    right:{},
    left:{
        display:'none',
    },
    show:{}



}))

const FWCard = (props) => {
    const classes = useStyles()
    const data = useStaticQuery(
        graphql`
      query {
        photos: allFile(filter: {sourceInstanceName: {eq:"images"}}) {
        nodes {
            relativePath
            childImageSharp {
            fluid(quality: 90, maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
            }
          }

        }
        
        }
      }
    `)
    const { position, image, title, subtitle, actions,bg } = props

    const generateTitle = () => {
        if (React.isValidElement(title)) {
            return (
                <>{title}</>

            )
        }
        return (
            <Typography className={classes.header} >{title}</Typography>
        )
    }
    const generateSubTitle = () => {
        if(!subtitle){return}
        if (React.isValidElement(subtitle)) {
            return (
                <>{subtitle}</>
                
            )
        }
        return (
            <Typography color="textSecondary"  className={classes.subtitle}>{subtitle}</Typography>
        )
    }
    const photo = data.photos.nodes.filter(item => item.relativePath === image)

    return (
        <Card className={`${classes.root} ${bg? classes.greyBg:''}`} square elevation={0}>
            
                <CardMedia className={`${classes.imageWrapper} ${classes.right} ${position === 'right'?classes.show:''}`}>
                    <Img fluid={photo[0].childImageSharp.fluid} className={classes.cover} />
                </CardMedia>
            
            <div className={classes.details} >
                <CardContent className={classes.content}>
                    <div className={classes.content}>
                        {generateTitle()}
                    </div>

                    <div className={classes.content}>
                        {generateSubTitle()}
                    </div>

                </CardContent>
                {actions?.length > 0 && <CardActions>
                    <div className={classes.controls}>
                        {actions.map((action, index) => {
                            return <Button fullWidth component={Link} to={action.to} key={index} className={classes.button}>{action.label}</Button>
                        })}
                    </div>
                </CardActions>}
            </div>

                <CardMedia className={`${classes.imageWrapper} ${classes.left} ${position !== 'right'?classes.show:''} `}>
                    <Img fluid={photo[0].childImageSharp.fluid} className={classes.cover} />
                </CardMedia>
            
        </Card>
    )

}
export default FWCard